@import "../../../global.scss";

//welcome message modal styling
.welcome-img-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.font-size {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #121212;
  letter-spacing: -2px;

  @include responsive($m) {
    font-size: 24px;
    line-height: 32px;
  }
}

.info {
  font-size: 16px;
  font-weight: 500;
  color: #4d4d4ddd;
  text-align: center;
  margin-top: 10px;
  line-height: 24px;

  @include responsive($m) {
    font-size: 12.8px;
    line-height: 19.2px;
  }
}

.message-show-box {
  height: 166px;
  border-radius: 8px;
  margin-top: 50px;
  background: linear-gradient(to right, #f0f1ff 50%, #dee2fe 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include responsive($m) {
    height: 100px;
    margin-top: 30px;
    border-radius: 4.8px;
  }
}

.hr-from-onboarding {
  border: 1px solid #e5e7eb;
  margin-top: 40px;
  margin-left: -25px;
  margin-right: -25px;

  @include responsive($m) {
    margin-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dark-line {
  border: 2px solid #616be9;
  width: 236.7px;

  @include responsive($m) {
    width: 142px;
  }
}

.message-show {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @include responsive($m) {
    gap: 10px;
  }
}

.number-show {
  font-size: 24px;
  font-weight: 500;
  color: #121212;
  text-align: center;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background: linear-gradient(114.82deg, #c5c7fb 9.07%, #989ef7 93.76%);
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive($m) {
    font-size: 10.29px;
    width: 25.4px;
    height: 25.4px;
  }
}

.text-message {
  display: flex;
  margin-left: 40px;
  gap: 190px;
  margin-top: 15px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #121212;

  @include responsive($m) {
    font-size: 7.8px;
    gap: 130px;
    margin-left: 20px;
  }
}

.video-on-boarding-bottom-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include responsive($m) {
    margin-top: 6%;
  }
  .video-on-boarding-active-dot-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include responsive($m) {
      justify-content: flex-start;
    }
  }
}

//Active dot among three dot styling
