.drafts-page{
    width: 100%;
    max-width: 1400px;
    padding: 0px 5%;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    //mobile styles
    @media (max-width: 480px){
        padding: 0px 12px;
    }
}
.all-posts-page{
    width: 100%;
    max-width: 1400px;
    padding: 0px 5%;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 480px){
        padding: 0px 12px;
    }
}
.all-post-card-day-box{
    margin: 16px 0px 0px 0px;
    padding-bottom: 16px;
    border-bottom: 1px solid #FFFFFF;
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    @media (max-width: 780px){
        grid-template-columns: repeat(1, 1fr);
    }
}
.all-posts-tab-toggle{
    display: flex;
    gap: 24px;
    width: fit-content;
    justify-content: flex-start;
    padding-left: 5px 0px 2px 24px;
    @media (max-width: 480px){
        width: 100%;
        justify-content: space-between;
        padding-left: 0px;
        gap: 16px;
        padding: 4px 0px;
    }
    .all-posts-tab-toggle-button{
        border: none;
        background: transparent;
        font-size: 14px;
        font-weight: 600;
        color: #746e5e;
        cursor: pointer;
        height: 42px;
        padding: 0px 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-bottom: 4px solid transparent;
        @media screen and (max-width: 480px){
            flex: 1;
            justify-content: center;
            padding: 8px;
            border-radius: 30px;
            background: #F6F6F6;
            height: 36px;
            border-bottom: none;
        }
    }
    .all-posts-tab-toggle-button-active{
        color: #121212;
        border-bottom: 4px solid #6366F1;
        @media screen and (max-width: 480px){
            background: #6366F1;
            color: white;
            border-bottom: none;
        }
    }
}