@import "../../global.scss";

$loader-size: 50px;
$primary-color: #1f1f1f;

@mixin loader-animation {
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.btns {
  display: flex;
  gap: 10px;
  margin-top: 5%;
}

.hr-connect {
  margin-top: 15%;
  margin-bottom: 5%;
  border: 1px solid #e5e7eb;
}

.title-connect {
  color: #121212;
  font-size: 20px;
  font-weight: 500;

  @include responsive($m) {
    font-size: 16px;
  }
}

.text-connect {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2.5%;

  @include responsive($m) {
    font-size: 12px;
  }
}

.anim-connect {
  margin-top: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include responsive($m) {
    img {
      width: 38px;
      height: 38px;
    }
  }
}

.custom-loader-linkedin {
  width: $loader-size;
  height: $loader-size;
  border-radius: 50%;
  z-index: 100;
  position: absolute;
  top: 42%;
  left: 42%;

  //transform: translate(-50%, -50%);

  background: radial-gradient(farthest-side, $primary-color 94%, #0000) top/8px
      8px no-repeat,
    conic-gradient(#0000 30%, $primary-color);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  @include loader-animation;
}
.view-modal {
  background: linear-gradient(
    178.6deg,
    #fdf9d7 -0.01%,
    rgba(255, 255, 255, 0) 34.24%
  );
}
.linkedin-company-modal-box {
  width: 630px;
  @include responsive($m) {
    width: 100%;
  }
  .linkedin-company-modal-header-img {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .linkedin-company-modal-profile-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    .linkedin-company-modal-profile-stack {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
  .linkedin-company-modal-footer-text-box {
    font-size: 24px;
    font-weight: 500;
    color: #121212;
    text-align: center;
    line-height: 24px;
    margin-bottom: 16px;
    @include responsive($m) {
      font-size: 14px;
      line-height: 14px;
    }
  }
  .linkedin-company-modal-footer-text-box-mobile {
    display: none;
    color: #121212;
    text-align: center;
    line-height: 24px;
    margin-bottom: 16px;
    @include responsive($m) {
      font-size: 14px;
      line-height: 14px;
    }
  }
  .linkedin-company-modal-footer-redirection-text {
    font-size: 16px;
    font-weight: 400;
    color: #625c4d;
    text-align: center;
    @include responsive($m) {
      font-size: 12px;
    }
  }
  .linkedin-company-modal-footer-redirection-timer {
    font-size: 24px;
    font-weight: 500;
    color: #121212;
    text-align: center;
    @include responsive($m) {
      font-size: 16px;
    }
  }
}
