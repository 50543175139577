.dropdown-container-box{
    position: relative;

    .dropdown-list{
        position: absolute;
        top: calc(100% + 4px);
    }
}
.sort-and-filter-label{
    background-color: #FFFFFF;
}
.sort-and-filter-label:hover{
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #D9D9D9;
}