.draft-card-content-box{
    width: 100%;
    border: 1px solid #FFFFFF;
    border-radius: 18px;
    padding: 24px;
    background-color: #FFFFFF;
    transition: all 0.3s ease;
    &:hover{
        border: 1px solid #3F3B31;
        box-shadow:  0px 4px 4px 0px #0000001A;
        .see-more-less .card-footer .toggle-btn {
            display: block;
        }
    }
    @media screen and (max-width: 900px) {
        padding: 16px;
    }
}


.draft-card-topic{
    white-space: pre-line;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.see-more-less{
    border: 1px solid #EDEDED;
    padding: 16px;
    border-radius: 12px;
    @media screen and (max-width: 900px) {
        padding: 12px;  
        border: none;
        border-bottom: 1px solid #E4E4E4;
        border-top: 1px solid #E4E4E4;
        border-radius: 0px;
        padding: 12px 0px;
    }
    .card-content-collapsed {
        color: #3F3B31;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        white-space: pre-line;
        word-break: break-word;
        font-family: Poppins;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        letter-spacing: 0.8;
        overflow: hidden;
    }
    .card-content-expanded {
        color: #3F3B31;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        white-space: pre-line;
        word-break: break-word;
        font-family: Poppins;
        letter-spacing: 0.8;
    }
    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0px 0px 0px;
        .attachment-number{
            font-size: 14px;
            font-weight: 500;
            color: #625C4D;
            white-space: nowrap;
            line-height: 40px;
        }
        .toggle-btn {
          color: #3F3B31;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
          display: none;
          border: none;
        }
      }
}
.all-post-card-box{
    width: 100%;
    height: fit-content;
    border: 1px solid #FFFFFF;
    border-radius: 18px;
    padding: 24px;
    background-color: #FFFFFF99;
    transition: all 0.3s ease;
    &:hover{
        border: 1px solid #3F3B31;
        box-shadow:  0px 4px 4px 0px #0000001A;
        .see-more-less .card-footer .toggle-btn {
            display: block;
        }
    }
    @media screen and (max-width: 900px) {
        padding: 16px;
    }

}
.all-post-card-button-box{
    
}