@import '../../../global.scss';

.toaster {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 40px;
  z-index:10000;
  padding : 20px;
  font-weight: 600;

  @include responsive($m) {
    width: 100%;
    padding: 0 10%;
    border-radius: 0px;
    font-size: 10px;

  }
}

.hidden {
  display: none;
}

.toast-content {
  max-width: 300px;
  text-align: center;
}

.top-left {
  top: 16px;
  left: 16px;
  animation: slideFromLeft 0.5s ease-in-out,slideOutFromLeft 0.5s ease-in-out 2.5s;
}

.top-right {
  top: 16px;
  right: 16px;
  animation:slideInFromRight 0.5s ease-in-out, slideOutFromRight 0.5s ease-in-out 2.5s;
}

.top-center{
    top: 16px;
    left: 40%;
    animation: slideInFromTop 0.5s ease-in-out, slideOutFromTop 0.5s ease-in-out 2.5s;

    @include responsive($m){
        left: 0%;
    }
}

.bottom-center{
    bottom: 16px;
    left: 50%;
    animation: slideIn 0.5s ease-in-out, slideOut 0.5s ease-in-out 2.5s;
   
}

.bottom-left {
  bottom: 16px;
  left: 16px;

  animation: slideIn 0.5s ease-in-out, slideOut 0.5s ease-in-out 2.5s;
}

.bottom-right {
  bottom: 16px;
  right: 16px;
  animation: slideIn 0.5s ease-in-out, slideOut 0.5s ease-in-out 2.5s ;
}


//for bottom sliding animation
@keyframes slideIn {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

//for top left sliding animation
@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutFromLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

//for top right sliding animation
@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutFromRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

//for top center sliding animation
@keyframes slideInFromTop {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideOutFromTop {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.toast-message{
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-align: center;
    background-color: #6366F1D9;
    margin: 0;
    padding: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 20px;
}

@keyframes toast-in {
    from {
        transform: translateX(-50%) translateY(0%);
    }
    to {
        transform: translateX(-50%) translateY(80px);
    }
    
}

