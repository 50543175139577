@import "../../../global.scss";

.video-on-boarding-heading {
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins";
  color: #121212;
  text-align: center;
  @include responsive($m) {
    font-size: 24px;
  }
}
.video-on-boarding-welcome-text {
  font-size: 28px;
  font-weight: 400;
  font-family: "Poppins";
  color: #121212;
  text-align: center;
  @include responsive($m) {
    font-size: 22px;
    font-weight: 500;
    text-wrap: nowrap;
  }
}
.video-on-boarding-video-container {
// <<<<<<< HEAD
  width: 100%;
  height: 88%;
  margin-top: 20px;
// =======
//   width: 95%;
//   aspect-ratio: 16/9;
//   margin-top: 16px;
// >>>>>>> ee67e8363f3cd43f05290f707b8ccfcce1693ef0
  border-radius: 12px;
  border: 2px solid #fdae38;
  padding: 12px;
  align-content: center;
  @include responsive($m) {
// <<<<<<< HEAD
    height: 90%;
// =======
//     height: auto;
//     aspect-ratio: 9/16;
// >>>>>>> ee67e8363f3cd43f05290f707b8ccfcce1693ef0
    margin-top: 10px;
  }
  .video-on-boarding-video {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
}
