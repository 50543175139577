@import "../../../global.scss";

.button-footer-container {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  .button-footer-left {
    flex:1;
    justify-content: flex-start;
  }
  .button-footer-middle {
    flex: 1;
    // margin-left: auto;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    .index-dots {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #d9d9d9;
    }
  }
  .button-footer-right {
    flex: 1;
    justify-content: flex-end;
    // margin-left: auto;
    display: flex;
    gap: 24px;
    align-items: center;
  }
}
