@import '../../../global.scss';

.left-banner {
    @include responsive($m) {
        display: none;
    }
    margin: 2vh;
    border-radius: 40px;
    width: 404px;
    min-height: 96vh;
    background: linear-gradient(0deg, #FFDDC5 0%,  #FFF4CF 100%);
}

.trust-marker{
    margin-top:4vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-profile-image{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2px;
    margin-left: -20px;

    img{
        width: 80%;
        height: 45vh;
        border-radius: 50%;
    }
}

.login-left-banner-text{
    width:100%;
    display: flex;
    margin-top:2vh;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 18px;
    
    div{
        color:#504B29;
    }

    span{
        color:#504B29;
        font-weight: 600;
    }
}