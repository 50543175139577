@import "../../../../global.scss";

.login-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 93vw;
  max-width: 1550px;
  margin: auto;
  height: 100vh;
  margin-top: auto;
  margin-bottom: auto;
  @include responsive($xl) {
    width: 95vw;
  }
  @include responsive($m) {
    width: 100vw;
  }
}
.main-main-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content:flex-end ;
  width: 43%;
  max-height: 780px;
  @include responsive($m) {
    background: white;
    height: 100%;
    width: 100%;
    position: relative;
    align-items: flex-start;
    max-height: 100vh;
  }
  .main-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 4%;
    @include responsive($m) {
      width: 100%;
      padding: 0 6%;
      margin-top: 8px;
    }
  }
}
.left-banner-container {
  width: 52%;
  overflow: hidden;
  max-height: 780px;
  border-radius: 24px;
  height:90vh;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  @include responsive($xl) {
    width: 55%;
  }
  @include responsive($m) {
    display: none;
  }
  .left-banner-carousel-img-container {
    position: relative;
    max-height: 780px;
    border-radius: 24px;
    height: 100%;
  }
}
.sub-main-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include responsive($m) {
    width: 100%;
  }
  .google-box {
    margin-top: 20px;
    @include responsive($xl) {
      margin-top: 10px;
    }
  }
  .login-page_trustmarker {
    display: flex;
    align-items: center;
    margin-top: 8px;
    // margin-bottom: 12px;
    margin-bottom: 12px;
    @include responsive($xl) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @include responsive($m) {
      margin-top: 18px;
    }
    .trustmarker-image {
      @include responsive($m) {
        display: none;
      }
    }
    .trustmarker-text {
      font-size: 12px;
      font-family: Poppins;
      font-weight: 400;
      margin-left: 8px;
      margin-right: 4px;
      @include responsive($m) {
        margin-left: 0px;
        font-size: 10px;
        font-weight: 500;
      }
      .trustmarker-text-highlight {
        font-weight: 600;
        font-size: 14px;
        @include responsive($m) {
          font-size: 11px;
        }
      }
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  font-family: "inter", sans-serif;
  margin-top: 7%;
  gap: 0.5rem;
  justify-content: center;

  @include responsive($m) {
    margin-top: 25px;
    height: 40px;
    font-size: 20px;
  }

  img {
    width: 40px;
    height: 40px;

    @include responsive($m) {
      width: 30px;
      height: 30px;
    }
  }
}

.logo-desktop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;

  img {
    width: 52px;
    height: 48px;

    @include responsive($m) {
      width: 23px;
      height: 23px;
    }
  }

  .logo-desktop {
    margin-top: 0%;

    @include responsive($m) {
      font-size: 16px;
      color: #1f1f1f;
    }
  }
  .logo-desktop-text {
    font-size: 32px;
    font-weight: 500;
    color: #262338;
    margin-top: 12px;
    @include responsive($xl) {
      font-size: 24px;
    }
    @include responsive($m) {
      font-size: 22px;
    }
  }
}

.signup {
  margin-top: 2%;
  font-weight: 400;
  font-size: 1.3rem;
  @include responsive($m) {
    font-size: 18px;
    margin-top: 10%;
  }
}

.withGoogle {
  border: 1px solid #e5e7eb;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 4%;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: $border-radius-for-button;
  padding: 0.2rem 0;
  color: #1f2937;
  cursor: pointer;
  @include responsive($m) {
    font-size: 12px;
    margin-top: 8%;
  }
  background: linear-gradient(to bottom, #ffffff 0%, #f7f8fa 100%);
  img {
    width: 30px;
    height: 44px;

    @include responsive($m) {
      width: 20px;
      height: 30px;
    }
  }
}

.dashed-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 14px;
}

.dashed-line {
  flex: 1;
  background-image: linear-gradient(90deg, #e5e7eb 50%, transparent 50%);
  background-size: 8px 1px;
  height: 1px;
  background-repeat: repeat;
}

.dashed-text {
  flex: 2;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #6b7280;

  @include responsive($m) {
    font-size: 12px;
  }
}

.text-input-container {
  margin-top: 2%;
  width: 100%;
}

.note-text {
  font-family: #4d4d4d;
  font-size: 12px;
  padding: 0 5%;
  margin-top: 0%;
  font-weight: 300;
  @include responsive($m) {
    margin-top: 8px;
  }
}

.toggle {
  margin-top: 5%;
  font-weight: 500;
  font-size: 14px;
  color: #4d4d4d;
  @include responsive($m) {
    font-size: 12px;
  }

  span {
    color: #6366f1;
    padding-left: 4px;
    cursor: pointer;
  }
}

.submit-button {
  width: 100%;
  margin-top: 20px;
  @include responsive($m) {
    margin-top: 50px;
  }
}

#email,
#password {
  font-size: 16px;

  @include responsive($m) {
    font-size: 12px;
  }
}

.loader-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 100%;
  margin-top: 5%;
  border: 1px solid #e5e7eb;
  border-radius: 50px;
  background: #e5e7eb;
}

.forget-password {
  font-size: 16px;
  margin-top: 2px;
  color: #6366f1;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  font-weight: 600;

  @include responsive($m) {
    font-size: 12px;
  }
}

.back-to-website {
  position: absolute;
  top: 0px;
  left: 30px;
  @include responsive($xl) {
    left: 26px;
  }
  @include responsive($m) {
    top: 20px;
  }
}

.get-free-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
  background: linear-gradient(
    270deg,
    rgba(79, 81, 216, 0) 0.96%,
    rgba(255, 166, 83, 0.15) 50.48%,
    rgba(79, 81, 216, 0) 100%
  );
  //   border-radius: 100px;
  padding: 12px 24px;
  margin-bottom: 20px;

  @include responsive($m) {
    margin-bottom: 0px;
    padding: 0px;
    margin-top: 24px;
    box-shadow: none;
  }

  .get-free-desktop-img {
    @include responsive($m) {
      width: 14px;
      height: 14px;
    }
  }

  .get-free-desktop-text {
    font-size: 18px;
    color: #4f51d8;
    font-weight: 500;
    @include responsive($xl) {
      font-size: 14px;
    }
    @include responsive($m) {
      font-size: 12px;
    }
  }
  .get-free-desktop-sub-text {
    font-weight: 400;
    font-size: 14px;
    color: #4f51d8;
    @include responsive($xl) {
      font-size: 12px;
    }
  }
}

.panel {
  width: 33vw;
  height: 97vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 40px;

  @include responsive($m) {
    display: none;
  }
}
/* From Uiverse.io by Shoh2008 */ 
.carousel-dot {
  width: 20px;
  height: 6px;
  margin-top: 2px;
  position: relative;
  left: -16px;
  border-radius: 10px;
  color: #E5E7EB;
  background: currentColor;
  box-shadow: 32px 0 , -32px 0 ,  64px 0;
}

.carousel-dot::after {
  content: '';
  position: absolute;
  left: -40px;
  top: 0;
 width: 20px;
  height: 6px;
  border-radius: 10px;
  background: #B5B1A6;
  animation: move_351 25s linear infinite;
}

@keyframes move_351 {
  0% , 20% {
    left: -32px;
    width: 20px;
  }
  25%  {
    left: -32px;
    width: 52px;
  }
  27% , 45% {
    left: 0px;
    width: 20px;
  }
  50%{
    left: 0px;
    width:52px;
  }
  52% , 70% {
    left: 32px;
    width: 20px;
  }
  75% {
    left: 32px;
    width: 52px;
  }
  77%{
    left: 64px;
    width: 20px;
  }
  100% {
    left: 64px;
    width: 20px;
  }
}
.fade-in-login-panel {
  animation: fadeInLogin 1s ease-in;
}

.fade-out-login-panel {
  animation: fadeOutLogin 1s ease-out;
}

@keyframes fadeInLogin {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}

@keyframes fadeOutLogin {
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  70% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-300%);
  }
}
