@import "../../../global.scss";

.page-overlay-lock {
  position: static;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 950;
  display: flex;
  justify-content: center;
  // padding-top: 30vh;
  backdrop-filter: blur(2px);
  .page-overlay-lock_content {
    position: -webkit-sticky;
    position: sticky;
    top: 30vh;
    height: fit-content;
    .page-overlay-lock_content_image {
      width: 36px;
      height: 36px;
      margin: 12px auto;
      margin-bottom: 24px;
    }
  }
}

//payment card styling

.payment-plan-card {
  width: 100%;
  @include responsive($m) {
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    // padding: 0px 2%;
    padding-top: -4px;
    padding-left: 16px;
    padding-right: 12px;
  }
  .payment-plan-card_header {
    max-width: 96%;
    border: 1px solid #ffc700;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #fff8e0;
    // font-size: 1.883vh;
    font-size: 16px;

    font-weight: 500;
    // font-size:1.95vh;
    font-family: Poppins;
    // margin-bottom: 20px;
    margin-bottom: 4px;
    color: #121212;
    @include responsive($xl) {
      font-size: 16px;
    }
    @include responsive($m) {
      margin-bottom: 0px;
      display: none;
      border: none;
      font-size: 16px;
      max-width: 94%;
      margin-bottom: 16px;
    }
  }
  .payment-plan-card_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-sizing: border-box;
    @include responsive($m) {
      flex-direction: column;
      padding-bottom: 8px;
    }
    .payment-plan-card_body_up {
      width: 100%;
      box-sizing: border-box;
      padding-right: 0px;
      @include responsive($m) {
        width: 100%;
        padding-right: 0px;
        border-right: none;
      }
      .payment-plan-card_body_up_title {
        font-size: 32px;
        //font-size:35px;
        font-family: Poppins;
        font-weight: 600;
        color: #6366f1;
        margin-bottom: 0.463vh;
        @include responsive($xl) {
          font-size: 28px;
        }
        @include responsive($m) {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }
      .payment-plan-card_body_up_trustmarker {
        display: flex;
        align-items: center;
        // margin-bottom: 12px;
        margin-bottom: 1.389vh;
        @include responsive($m) {
          margin-bottom: 20px;
          border-radius: 50px;
          background: #eafff6;
          height: 24px;
          padding: 16px 12px;
          max-width: 275px;
          margin-top: 12px;
          margin-bottom: 14px;
        }
        .trustmarker-image {
          @include responsive($m) {
            display: none;
          }
        }
        .trustmarker-text {
          font-size: 12px;
          font-family: Poppins;
          font-weight: 400;
          margin-left: 8px;
          margin-right: 4px;
          @include responsive($m) {
            margin-left: 0px;
            font-size: 10px;
            font-weight: 500;
          }
          .trustmarker-text-highlight {
            font-weight: 600;
            font-size: 14px;
            @include responsive($m) {
              font-size: 11px;
            }
          }
        }
      }
      .payment-plan-card_body_up_timer {
        width: 100%;
        display: flex;
        align-items: center;
        background: #fff1f1;
        border: 1px solid #fef0f0;
        // padding: 6px 24px;
        padding: 2px 32px;
        border-radius: 4px;
        height: fit-content;

        justify-content: space-between;
        @include responsive($m) {
          display: flex;
          flex-direction: column;
          background: #fff1f1;
          border-radius: 8px;
          padding: 4px 12px;
          height: fit-content;
        }
        .payment-plan-card_body_up_timer_title {
          width: 50%;
          font-size: 18px;
          font-weight: 700;
          @include responsive($xl) {
            font-size: 16px;
          }
          @include responsive($m) {
            width: 100%;
            font-size: 16px;
            font-weight: 600;
          }
          .payment-plan-card_body_up_timer_title_percentage {
            // font-size: 20px;
            font-size: 18px;
            font-weight: 700;
            @include responsive($xl) {
              font-size: 18px;
            }
            color: #34c759;
            @include responsive($m) {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
        .payment-plan-card_body_up_timer_text {
          width: 50%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-end;
          gap: 16px;
          @include responsive($m) {
            width: 100%;
            flex-direction: row-reverse;
            width: 100%;
            justify-content: flex-end;
            gap: 20px;
          }
          .payment-plan-card_body_up_timer_text_hurry {
            font-weight: 600;
            font-size: 12px;
            color: #000000;
            @include responsive($m) {
              font-size: 14px;
            }
          }
          .payment-plan-card_body_up_timer_time {
            display: flex;
            width: 25%;
            align-items: center;
            gap: 8px;
            color: #ff2d55;
            font-size: 16px;
            // font-size:18px;
            font-weight: 700;
            @include responsive($xl) {
              font-size: 16px;
            }
            @include responsive($m) {
              font-size: 16px;
              font-weight: 700;
              width: 100px;
            }
            .payment-plan-card_body_up_timer_time_unit {
              display: flex;
              flex-direction: column;
              align-items: center;

              .payment-plan-card_body_up_timer_time_value {
                color: #ff2d55;
                line-height: 22px;

                // line-height: 2.77vh;
                @include responsive($m) {
                  font-size: 16px;
                  font-weight: 700;
                }
              }
              .payment-plan-card_body_up_timer_time_value_unit {
                color: #ff2d55;
                font-size: 12px;
                // font-size: 1.38vh;
                font-weight: 600;
                @include responsive($m) {
                  display: none;
                }
              }
            }
          }
        }
      }
      // .banner-image-desktop{
      //     @include responsive($m){
      //         display: none;
      //     }
      // }
    }
    .payment-plan-card_body_right {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      // margin: 16px 0px;
      margin: 1.85vh 0px;
      flex-direction: row-reverse;
      justify-content: space-between;
      @include responsive($m) {
        width: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column-reverse;
      }
      .payment-plan-card_body_right_title {
        font-size: 18px;
        font-family: Poppins;
        font-weight: 500;
        color: #3f3b31;
        // margin-bottom: 8px;
        margin-bottom: 0.926vh;
        @include responsive($m) {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 12px;
        }
        .subhead-upper {
          font-size: 24px;
          .subhead-upper-inner {
            font-weight: 600;
            color: #068d3c;
          }
        }
        .subhead-bottom {
          font-size: 18px;
        }
      }
      .plan-card {
        width: 48.5%;
        max-width: 100%;
        // padding: 8px 16px;
        padding: 14px;
        padding-top: 6px;
        height: max-content;
        // height: 25.5vh;
        // max-height: 220px;
        border: 1px solid #6366f1;
        background: #6366f1;
        color: #ffffff;
        box-shadow: 8px 6px 6px 0px #0008ff0f;
        border-radius: 16px;
        margin-bottom: 2px;
        transition: all 0.3s ease;

        @include responsive($m) {
          padding: 16px;
          margin-bottom: 16px;
          width: 100%;
          border-radius: 24px;
          height: fit-content;
        }

        .plan-card_inner_box {
          // display: flex;
          // justify-content: space-between;
          // align-items: flex-start;
          .recommended-box-heading-balance {
            height: 20px;
            @include responsive($xl) {
              height: 18px;
            }
          }
          .plan-card_title_box {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 4%;
          }
          .plan-card_recommended {
            font-size: 16px;
            font-weight: 500;
            color: #6366f1;
          }
          .plan-card_name {
            font-size: 20px;
            // font-size: 2.7vh;
            font-family: Poppins;
            font-weight: 700;
            text-align: center;
            // color: #000000;
            @include responsive($xl) {
              font-size: 20px;
            }
            @include responsive($m) {
              font-size: 20px;
              font-weight: 500;
              text-wrap: nowrap;
            }
          }
          .plan-card_discount-box {
            display: none;
            align-items: center;
            @include responsive($m) {
              display: flex;
              transform: translateY(-1px);
            }
            .plan-card_discount-left-bookmark {
              background: #34c759;

              @include responsive($m) {
                height: 22px;
                border: 11px solid transparent;
                border-radius: 2px 0px 0px 2px;
              }
            }
            .plan-card_discount-right-bookmark {
              background: #34c759;
              border-radius: 0px 2px 2px 0px;
              @include responsive($m) {
                height: 22px;
                border: 11px solid transparent;
                border-radius: 2px 0px 0px 2px;
              }
            }
            .plan-card_discount_value {
              font-size: 14px;
              font-family: Poppins;
              font-weight: 600;
              color: #ffffff;
              background: #34c759;
              border: none;
              @include responsive($xl) {
                font-size: 12px;
              }
              @include responsive($m) {
                height: 22px;
                padding-top: 1px;
                font-size: 12px;
                text-wrap: nowrap;
              }
            }
          }
          .plan-card_name_subheading {
            color: #068d3c;
            font-size: 18px;
            font-family: Poppins;
            font-weight: 500;
            margin-top: 12px;
          }
          .plan-card_prices {
            margin-top: 10px;
            // margin-top: 2.58vh;
            // margin-bottom: 2.58vh;
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: center;
            @include responsive($xl) {
              margin-top: 8px;
            }
            @include responsive($m) {
              gap: 4px;
              margin-top: 16px;
              justify-content: flex-start;
            }
            // align-items: flex-start;
            // align-items: center;
            // justify-content: center;
            .plan-card_discount-box {
              display: flex;
              align-items: center;
              transform: translateY(-4px);
              @include responsive($m) {
                display: none;
              }
              .plan-card_discount-left-bookmark {
                background: #34c759;
                height: 24px;
                // height: 3.2vh;
                border: 12px solid transparent;
                // border: 1.6vh solid transparent;
                border-radius: 2px 0px 0px 2px;
              }
              .plan-card_discount-right-bookmark {
                background: #34c759;
                height: 24px;
                // height: 3.2vh;
                // border: 1.6vh solid transparent;

                border: 12px solid transparent;
                border-radius: 0px 2px 2px 0px;
              }
              .plan-card_discount_value {
                font-size: 14px;
                // font-size: 1.85vh;
                font-family: Poppins;
                font-weight: 600;
                color: #ffffff;
                height: 24px;
                // height: 3.2vh;
                padding-top: 2px;
                // padding-top: 0.23vh;
                background: #34c759;
                @include responsive($xl) {
                  font-size: 12px;
                }
              }
            }
            .plan-card_chip {
              background-color: #6366f1;
              color: #ffffff;
              padding: 4px 16px;
              border-radius: 40px;
              font-size: 12px;
              font-family: Poppins;
              font-weight: 500;
              width: fit-content;
            }
          }

          .price_box {
            display: flex;
            align-items: center;
            gap: 8px;
            // margin-bottom: 0.694vh;
            margin-bottom: 6px;
            @include responsive($m) {
              margin-bottom: 8px;
            }
            .offered-price {
              text-align: right;
              font-size: 24px;
              // font-size: 3.24vh;
              font-family: sans-serif;
              font-weight: 700;
              @include responsive($xl) {
                font-size: 22px;
              }
              @include responsive($m) {
                font-size: 24px;
              }
              // line-height: 36px;
              // color: #000000;
            }
            .offered-price-text {
              font-size: 16px;
              // font-size: 2.08vh;
              font-weight: 500;
              font-family: poppins;
              @include responsive($xl) {
                font-size: 16px;
              }
              @include responsive($xl) {
                font-size: 16px;
              }
              // color: #000000;
            }
          }
          .plan-card-discount-actual-price {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            @include responsive($m) {
              transform: translateY(-6px);
            }
          }
          .price-label {
            width: 100%;
            padding-left: 6px;
            font-size: 14px;
            // font-size: 1.6vh;
            font-family: Poppins;
            font-weight: 400;
            color: #000000;
            line-height: 10px;
            text-align: center;
            margin-bottom: 12px;
            @include responsive($m) {
              text-align: start;
            }
          }
          .actual-price {
            font-size: 18px;
            // font-size: 2.08vh;
            font-family: sans-serif;
            font-weight: 400;
            // color: #00000059;
            text-decoration: line-through;
            @include responsive($xl) {
              font-size: 14px;
            }
            @include responsive($m) {
              font-size: 14px;
            }
          }
        }
      }

      .banner-image-mobile {
        display: none;
        @include responsive($m) {
          display: block;
          margin-bottom: 16px;
        }
      }
    }
    .payment-plan-card_body_down {
      background: #fff8e0;
      margin-left: -32px;
      margin-right: -32px;
      margin-bottom: -32px;
      padding-left: 34px;
      padding-top: 6px;
      border-radius: 16px;
      padding-bottom: 12px;
      padding-right: 34px;
      @include responsive($m) {
        margin-left: 0px;
        margin-right: 0px;
        // margin-bottom: 0px;
        padding-top: 10px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 10px;
        border-radius: 8px 8px 0px 0px;
      }
      .payment-plan-card_body_down_title {
        font-size: 20px;
        // font-size: 3.58vh;
        font-weight: 600;
        background: linear-gradient(90deg, #f7ac07 0%, #e1791c 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        @include responsive($xl) {
          font-size: 26px;
        }
        @include responsive($m) {
          font-size: 24px;
          font-weight: 600;
          color: #121212;
          background: #121212;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .payment-plan-card_body_down_features {
        // display: inline-block;
        // flex-direction: row;
        // align-items: center;
        // margin: 4px 0px;
        // justify-content: space-between;
        // column-count: 3;
        display: grid;
        padding-bottom: 16px;
        grid-template-columns: repeat(3, 1fr); // 3 columns
        // grid-template-rows: repeat(5, 1fr);
        @include responsive($m) {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          margin: 0px 0px;
        }

        .features-normal-text {
          font-size: 12px;
          // font-size: 1.85vh;
          font-weight: 400;
          text-wrap: wrap;
          @include responsive($xl) {
            font-size: 14px;
          }
          @include responsive($m) {
            font-size: 14px;
          }
        }
        .features-bold-text {
          font-size: 13px;
          // font-size: 1.85vh;
          font-weight: 700;
          @include responsive($xl) {
            font-size: 14px;
          }
          @include responsive($m) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .payment-plan-card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    @include responsive($m) {
      display: none;
    }
    .footer_center_items {
      display: flex;
      align-items: center;
      gap: 16px;
      max-width: 70vw;
      overflow-x: scroll;
      flex-wrap: nowrap;
      .footer_center_item {
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
        .footer_center_item_icon {
          width: 24px;
          height: 24px;
        }
        .footer_center_item_text {
          font-size: 12px;
          font-family: Poppins;
          font-weight: 400;
          color: #121212;
          text-wrap: nowrap;
        }
      }
    }
  }
}

.free-payment-plan-card {
  width: 100%;
  @include responsive($m) {
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
  }
  .free-payment-plan-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    @include responsive($m) {
      display: block;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 24px;
    }
    .free-payment-plan-card_body_left_title {
      font-size: 30px;
      font-family: Poppins;
      font-weight: 400;
      color: #121212;
      margin-bottom: 8px;
      span {
        color: #ed6418;
      }
      @include responsive($m) {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }
    .free-payment-plan-card_body_left_trustmarker {
      display: flex;
      align-items: center;
      @include responsive($m) {
        margin-bottom: 12px;
      }
      .trustmarker-image {
        @include responsive($m) {
          width: 48px;
        }
      }
      .trustmarker-text {
        font-size: 12px;
        font-family: Poppins;
        font-weight: 400;
        margin-left: 8px;
        margin-right: 4px;
      }
    }
  }

  .payment-plan-card_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-bottom: 24px;
    @include responsive($m) {
      flex-direction: column;
      padding-bottom: 8px;
    }
    .payment-plan-card_body_left {
      width: 60%;
      box-sizing: border-box;
      padding-right: 24px;
      border-right: 1px solid #d9d9d9;
      @include responsive($m) {
        width: 100%;
        padding-right: 0px;
        border-right: none;
      }
      .banner-image-desktop {
        @include responsive($m) {
          display: none;
        }
      }
    }
    .payment-plan-card_body_right {
      width: 38%;
      box-sizing: border-box;
      padding-left: 24px;
      @include responsive($m) {
        width: 100%;
        padding-left: 0px;
      }
      .payment-plan-card_body_right_title {
        font-size: 18px;
        font-family: Poppins;
        font-weight: 500;
        color: #3f3b31;
        margin-bottom: 8px;
        @include responsive($m) {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 12px;
        }
      }
      .plan-card {
        padding: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-bottom: 8px;
        @include responsive($m) {
          padding: 12px;
          margin-bottom: 12px;
        }
        .plan-card_chip {
          background-color: #6366f1;
          color: #ffffff;
          padding: 4px 16px;
          border-radius: 40px;
          font-size: 12px;
          font-family: Poppins;
          font-weight: 500;
          width: fit-content;
          margin-bottom: 6px;
        }
        .plan-card_inner_box {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .recommended-box-heading-balance {
            height: 20px;
            @include responsive($xl) {
              height: 18px;
            }
          }
          .plan-card_name {
            font-size: 18px;
            font-family: Poppins;
            font-weight: 500;
            color: #000000;
            @include responsive($m) {
              font-size: 14px;
            }
          }
          .plan-card_name_subheading {
            color: #068d3c;
            font-size: 12px;
            font-family: Poppins;
            font-weight: 500;
          }
          .plan-card_prices {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            .actual-price {
              font-size: 24px;
              font-family: Poppins;
              font-weight: 400;
              color: #00000059;
              text-decoration: line-through;
            }
          }
          .price_box {
            .offered-price {
              text-align: right;
              font-size: 28px;
              font-family: Poppins;
              font-weight: 700;
              // color: #000000;
            }
            .price-label {
              text-align: right;
              font-size: 12px;
              font-family: Poppins;
              font-weight: 500;
              color: #000000;
            }
          }
        }
      }
      .banner-image-mobile {
        display: none;
        @include responsive($m) {
          display: block;
          margin-bottom: 16px;
        }
      }
    }
  }
  .payment-plan-card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    @include responsive($m) {
      display: none;
    }
    .footer_center_items {
      display: flex;
      align-items: center;
      gap: 16px;
      .footer_center_item {
        display: flex;
        align-items: center;
        gap: 8px;
        .footer_center_item_icon {
          width: 24px;
          height: 24px;
        }
        .footer_center_item_text {
          font-size: 12px;
          font-family: Poppins;
          font-weight: 400;
          color: #121212;
        }
      }
    }
  }
}
.recommended-card-highlight-feature-box {
  margin-top: 6px;
  margin-bottom: 6px;
  .recommended-card-highlight-feature {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    @include responsive($m) {
      justify-content: flex-start;
    }
  }
  .recommended-feature-text {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    @include responsive($xl) {
      font-size: 12px;
    }
    @include responsive($m) {
      text-align: start;
    }
  }
}
.footer_center_items::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.plan-highlight-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 24px 0px;
  @include responsive($m) {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }

  .plan-highlight-card {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    @include responsive($m) {
      padding: 8px 0px;
    }
    .plan-highlight-card-icon {
      width: 36px;
      height: 36px;
      img {
        width: 24px;
      }
    }
    .plan-highlight-card-text {
      font-size: 12px;
      font-family: Poppins;
      font-weight: 400;
      color: #121212;
      @include responsive($m) {
        line-height: 16px;
      }
    }
  }
}

.feature-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  gap: 6px;
  margin: 4px;
  @include responsive($m) {
    margin: 6px 0px;
    min-height: 2vh;
    width: auto;
  }
  .feature-text-icon {
    width: 18px;
    height: 18px;
    transform: translateY(3px);
    @include responsive($xl) {
      width: 16px;
      height: 16px;
    }
    @include responsive($m) {
      width: 14px;
      height: 14px;
    }
  }
  .feature-text-text {
    font-size: 16px;
    font-family: Poppins;
    color: #111111;
    @include responsive($m) {
      font-size: 14px;
    }
  }
}
