.custom-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
}
.primary-btn{
    background-color: #FFC700;
    border: 1px solid #FFC700;
    color: #121212;
    font-size: 16px;
    font-weight: 500;
}
.primary-btn:hover{
    background-color: #f3d464;
}
.secondary-btn{
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    color: #625C4D;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
}
.secondary-btn:hover{
    background-color: #FFC700;
}
.round-icon-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #D9D9D9;
}
.delete-button{
    border: none;
    background-color: transparent;
    fill: #625C4D;
    transition: all 0.3s ease;
}
.delete-button:hover{
    background-color: #FF0000;
    fill: #FFFFFF;
    border: 1px solid #FF0000;
}