@import "../../../global.scss";

.pricing-landing-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto 24px auto;
  max-width: 1200px;
  height: 90vh;
  overflow: hidden;
  @include responsive($m) {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    height: fit-content;
  }
  @include responsive($xxl) {
    margin: 0px 32px 0px 24px;
    max-width: 1300px;
  }
  @include responsive($xl) {
    margin: 0px 1.5% 0px 1.5%;
    max-width: fit-content;
  }

  .left-main-container {
    width: 68%;
    overflow-y: auto;
    padding-top: 24px;
    @include responsive($xl) {
      width: 62%;
    }
    @include responsive($m) {
      width: 100%;
      padding-top: 0px;
      padding: auto 8px;
    }
    .benefits-main-container {
      margin-top: 32px;
      margin-bottom: 32px;
      @include responsive($m) {
        padding-left: 8px;
        padding-right: 8px;
      }
      .benefits-heading {
        font-size: 24px;
        // margin: 18px 0px;
        font-weight: 500;
        color: #121212;
        @include responsive($m) {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .benefits-sub-heading {
        font-weight: 400;
        font-size: 12px;
        color: #121212;
      }
      .benefits-card-container {
        margin-top: 12px;
        background: #ffffff;
        padding: 12px 24px;
        // column-count: 3;
        gap: 2px;
        border-radius: 8px;
        // display: inline-block;
        display: grid;
        grid-template-columns: auto auto auto;
        @include responsive($m) {
          background: none;
          grid-template-columns: auto auto;
          padding-left: 0px;
          padding-right: 0px;
          gap: 16px;
        }
        .benefits-card {
          padding: 16px 0px;
          width: 85%;
          max-width: 220px;
          @include responsive($xl) {
            width: 90%;
          }
          @include responsive($m) {
            width: 98%;
            border-radius: 4px;
            background: #ffffff;
            padding: 8px;
            min-height: 110px;
            height: fit-content;
            border-left: 1px solid #e1791c;
          }
          .benefits-card-title {
            font-size: 14px;
            font-weight: 600;
            color: #111111;
            @include responsive($m) {
              font-size: 12px;
              text-wrap: nowrap;
            }
          }
          .benefits-card-description {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            @include responsive($m) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .outcomes-main-container {
      margin-top: 32px;
      .outcomes-heading {
        font-size: 24px;
        font-weight: 500;
        color: #0c1620;
        @include responsive($m) {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .outcomes-sub-heading {
        font-size: 12px;
        font-weight: 400;
        color: #525252;
      }
      .outcomes-analytics-container {
        margin-top: 12px;
        border-radius: 8px;
        background-color: #ffffff;
        padding: 24px;
        @include responsive($m) {
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 16px;
          padding-bottom: 16px;
        }
        .outcomes-mobile-heading {
          display: none;
          @include responsive($m) {
            display: initial;
          }
        }
        .key-outcomes-main-container {
          background: linear-gradient(154.74deg, #fff4cf 0%, #ffddc5 100%);
          box-shadow: 29px 8px 67px 0px #ffeec4 inset;
          margin: 4px 0px;
          border-radius: 10px;
          height: 45px;
          padding: 24px 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          @include responsive($m) {
            margin: 16px 0px;
            flex-direction: column;
            height: fit-content;
            align-items: flex-start;
            padding: 14px 16px;
            gap: 12px;
          }
        }

        .before-after-container {
          margin-top: 24px;
          padding-left: 16px;
          padding-right: 16px;
          margin-bottom: 40px;
          @include responsive($m) {
            padding-left: 0px;
            padding-right: 0px;
          }
          .before-after-grid {
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 16px;
            align-items: center;
            justify-self: center;
            @include responsive($m) {
              grid-template-columns: auto 40px auto;
              gap: 12px;
            }
            .before-after-grid-heading {
              font-size: 24px;
              font-weight: 700;
              color: #1e2d3e;
              margin: 0px auto;
              @include responsive($m) {
                font-size: 12px;
                font-weight: 600;
              }
            }
            .before-after-grid-image {
              @include responsive($m) {
                width: 24px;
                height: 24px;
              }
            }
            .before-after-grid-arrow-image {
              @include responsive($m) {
                width: 18px;
                height: 12px;
              }
            }
          }
        }
      }
    }
    .still-confused-mobile {
      padding: 21px 24px;
      margin-left: 8px;
      margin-right: 8px;
      background: #363782;
      border-radius: 8px;
      margin-top: 32px;
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @include responsive($m) {
        display: flex;
      }
      .still-confused-mobile-text {
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
      }
    }
    .still-confused-main-container {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      background: linear-gradient(0deg, #ffd585, #ffd585);
      border-radius: 8px;
      border: 1px solid #ffddc5;
      position: relative;
      margin-top: 32px;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include responsive($m) {
        display: block;
        margin-left: 8px;
        margin-right: 8px;

      }
      .still-confused-text-box {
        width: 70%;
        display: flex;
        max-width: 300px;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        left:180px;
        @include responsive($xl){
          width:45%;
          left:140px;
        }
        @include responsive($m) {
          position: initial;
          left:0px;
          width: 70%;
        }
        .still-confused-title {
          font-size: 14px;
          font-weight: 600;
          color: #121212;
          @include responsive($xl) {
            font-size: 12px;
          }
        }
        .still-confused-description {
          font-size: 12px;
          font-weight: 500;
          color: #4e4e4e;
          @include responsive($xl) {
            font-size: 11px;
          }
        }
      }
      .still-confused-image {
        position: absolute;
        left:40px;
        bottom:0;
        height: 105px;
        @include responsive($xl){
          left:30px;
        }
        @include responsive($m) {
          height: 100px;
          bottom: 0px;
          right: 16px;
          left: auto;
        }
      }
      .call-now-box {
        background: #171819;
        width:30%;
        max-width: 300px;
        margin: 0px auto;
        position: absolute;
        border-radius: 8px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        padding: 10px;
        right:40px;
        // @include responsive($xl) {
        //   transform: translate(0.5%, 44%);
        // }
        @include responsive($m) {
          position: absolute;
          transform: translate(-5%, 35%);
          width: 90%;
          right:0px;
          max-width:none ;
        }
      }
    }
  }
  .right-main-container {
    width: 30%;
    padding-top: 24px;
    @include responsive($xl) {
      width: 36%;
    }
    @include responsive($m) {
      width: 100%;
    }
    .payment-plan-card_body_up_title {
      font-size: 28px;
      font-family: Poppins;
      font-weight: 500;
      color: #6366f1;
      margin-bottom: 8px;
      @include responsive($xl) {
        font-size: 24px;
      }
      @include responsive($m) {
        font-size: 18px;
        margin: auto 16px;
      }
    }
    .payment-plan-card_body_up_trustmarker {
      display: flex;
      align-items: center;
      @include responsive($m) {
        margin: auto 16px;
      }
      .trustmarker-text {
        font-size: 12px;
        font-family: Poppins;
        font-weight: 400;
        margin-left: 8px;
        margin-right: 4px;
        @include responsive($xl) {
          font-size: 10px;
        }
        .payment-plan-card-body-up-trustmarker-img {
          width: 74px;
          @include responsive($xl) {
            width: 60px;
          }
          @include responsive($m) {
            width: 68px;
          }
        }
        .trustmarker-text-highlight {
          font-weight: 600;
          font-size: 12px;
          @include responsive($xl) {
            font-size: 10px;
          }
        }
      }
    }
    .payment-section {
      background: #ffffff;
      border-radius: 8px;
      padding: 16px;
      margin-top: 16px;
      .offer-text {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #2a2a2a;
        @include responsive($xl) {
          font-size: 20px;
        }
        @include responsive($m) {
          font-size: 18px;
          text-align: left;
          font-weight: 600;
        }
        .percent-off-text {
          color: #34c759;
          font-weight: 700;
          @include responsive($m) {
            font-weight: 600;
          }
        }
      }
      .payment-plan-card-timer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        justify-content: space-between;
        padding: 7px 13px;
        background: #fff1f1;

        border: 1px solid #fef0f0;
        border-radius: 4px;
        .payment-plan-card_body_up_timer_time {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #ff2d55;
          font-size: 16px;
          font-weight: 600;
          @include responsive($m) {
            font-size: 14px;
          }
          .payment-plan-card_body_up_timer_time_value {
            color: #ff2d55;
          }
        }
        .hurry-up-text {
          font-size: 12px;
          font-weight: 500;
          color: #111111;
        }
      }
      .payment-plan-card_body_pricing_landing {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        gap: 12px;
        margin-top: 16px;
        flex-direction: column-reverse;
        .plan-card {
          max-width: 100%;
          padding: 16px 16px 14px 23px;
          height: fit-content;
          border: 1px solid #6366f1;
          background: #6366f1;
          color: #ffffff;
          box-shadow: 8px 6px 6px 0px #0008ff0f;
          border-radius: 16px;
          margin-bottom: 2px;
          transition: all 0.3s ease;
          @include responsive($m) {
            padding: 16px;
            height: fit-content;
          }
          .plan-card_inner_box {
            .plan-card_title_box {
              display: flex;
              flex-direction: row;
              gap: 4%;
            }
            .plan-card_name {
              font-size: 20px;
              font-family: Poppins;
              font-weight: 500;
              text-wrap: nowrap;
              @include responsive($xl) {
                font-size: 18px;
              }
              @include responsive($m) {
                font-size: 16px;
              }
            }
            .plan-card_discount-box {
              align-items: center;
              display: flex;
              margin-left: 24px;
              @include responsive($l) {
                margin-left: auto;
              }
              .plan-card_discount-left-bookmark {
                background: #34c759;
                height: 20px;
                border: 10px solid transparent;
                border-radius: 2px 0px 0px 2px;
              }
              .plan-card_discount-right-bookmark {
                background: #34c759;
                height: 20px;
                border: 10px solid transparent;
                border-radius: 2px 0px 0px 2px;
              }
              .plan-card_discount_value {
                font-size: 12px;
                font-family: Poppins;
                font-weight: 600;
                color: #ffffff;
                background: #34c759;
                border: none;
                height: 20px;
                padding-top: 1px;
                text-wrap: nowrap;
              }
            }
            .plan-card_prices {
              margin-bottom: 8px;
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              gap: 8px;
              align-items: center;

              .price-label {
                width: 100%;
                margin-top: 6px;
                font-size: 11px;
                font-family: Poppins;
                font-weight: 400;
                color: #000000;
                line-height: 10px;
                @include responsive($m) {
                  font-size: 10px;
                  margin-top: 4px;
                }
              }
            }
            .price_box {
              display: flex;
              align-items: center;
              gap: 8px;
              .offered-price {
                text-align: right;
                // font-size: 28px;
                font-size: 26px;
                font-family: sans-serif;
                font-weight: 700;
                @include responsive($xl) {
                  font-size: 22px;
                }
                @include responsive($m) {
                  font-size: 20px;
                  font-weight: 700;
                }
              }
              .offered-price-text {
                font-size: 16px;
                font-weight: 500;
                font-family: poppins;
                @include responsive($m) {
                  font-size: 16px;
                  font-weight: 500;
                }
              }
            }
            .plan-card-discount-actual-price {
              display: flex;
              flex-direction: column;
              transform: translateY(-2px);
              @include responsive($xl) {
                transform: translateY(-4px);
              }
              @include responsive($m) {
                transform: translateY(-6px);
              }
            }
            .actual-price {
              // font-size: 18px;
              font-size: 14px;
              font-family: sans-serif;
              font-weight: 400;
              // color: #00000059;
              text-decoration: line-through;
              @include responsive($m) {
                font-size: 12px;
              }
            }
          }
          .payment-button-container {
            @include responsive($m) {
              display: none;
            }
          }
        }

        .banner-image-mobile {
          display: none;
          @include responsive($m) {
            display: block;
            margin-bottom: 16px;
          }
        }
      }
    }
    .still-confused-main-container {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      background: linear-gradient(0deg, #ffd585, #ffd585);
      border-radius: 8px;
      border: 1px solid #ffddc5;
      position: relative;
      margin-top: 12px;
      height: 116px;
      @include responsive($m) {
        display: none;
      }
      .still-confused-text-box {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .still-confused-title {
          font-size: 14px;
          font-weight: 600;
          color: #121212;
          @include responsive($xl) {
            font-size: 12px;
          }
        }
        .still-confused-description {
          font-size: 12px;
          font-weight: 500;
          color: #4e4e4e;
          @include responsive($xl) {
            font-size: 11px;
          }
        }
      }
      .still-confused-image {
        position: absolute;
        bottom: 0px;
        right: 24px;
        height: 100px;
      }
      .call-now-box {
        background: #171819;
        width: 90%;
        margin: 0px auto;
        position: absolute;
        border-radius: 8px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        padding: 10px;
        transform: translate(1%, 35%);
        @include responsive($xl) {
          transform: translate(0.5%, 44%);
        }
      }
    }
  }
}
.key-outcomes {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .success-mark-container {
    height: 18px;
    width: 18px;
  }
  .key-outcomes-point-text {
    font-size: 14px;
    font-weight: 500;
    color: #111111;
    @include responsive($xl) {
      font-size: 12px;
    }
    @include responsive($m) {
      font-size: 12px;
      font-size: 600;
    }
  }
}
.comparison-card-container {
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  height: 90px;
  max-width: 258px;
  width: 258px;
  margin-left: auto;
  margin-right: auto;
  @include responsive($xl) {
    height: fit-content;
    padding: 8px;
    max-width: 175px;
    width: 175px;
  }
  @include responsive($m) {
    height: fit-content;
    padding: 8px;
    max-width: 130px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .comparison-card-data {
    font-size: 20px;
    font-weight: 700;
    color: #1e2d3e;
    @include responsive($m) {
      font-size: 14px;
    }
  }
  .comparison-card-text {
    font-size: 14px;
    font-weight: 500;
    color: #1e2d3e;
    @include responsive($m) {
      font-size: 11px;
    }
  }
  .comparison-card-arrow {
    @include responsive($m) {
      height: 8px;
      width: 8px;
    }
  }
  .comparison-card-percentage-data {
    font-size: 14px;
    font-weight: 700;
    @include responsive($m) {
      font-size: 10px;
    }
  }
  .comparison-card-time {
    font-size: 12px;
    font-weight: 500;
    color: #1e2d3e;
    margin-left: 6px;
    @include responsive($m) {
      font-size: 8.5px;
      font-weight: 400;
      margin-left: 2px;
    }
  }
}

.whyReachifyMe-main-container {
  @include responsive($m) {
    padding-left: 8px;
    padding-right: 8px;
  }
  .whyReachifyMe-heading {
    font-size: 24px;
    font-weight: 500;
    color: #121212;
    @include responsive($m) {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.whyReachifyMe-card-grid-container {
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include responsive($m) {
    flex-direction: column;
    gap: 16px;
  }
  .whyReachifyMe-card-container {
    width: 32%;
    max-width: 300px;
    border-radius: 10px;
    padding: 16px 24px;
    @include responsive($xl) {
      padding: 10px 12px;
      max-width: 265px;
    }
    @include responsive($m) {
      width: 100%;
      max-width: 100%;
      padding: 12px 24px;
    }
    .whyReachifyMe-card-title {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      margin-top: 10px;
      margin-bottom: 10px;
      text-wrap: nowrap;
      @include responsive($xl) {
        font-size: 12px;
      }
      @include responsive($m) {
        font-size: 12px;
        display: none;
      }
    }
    .whyReachifyMe-card-img-text {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      margin-top: 8px;
      margin-bottom: 8px;
      @include responsive($m) {
        gap: 8px;
      }
      .whyReachifyMe-card-img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .whyReachifyMe-card-title {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        margin-top: 8px;
        margin-bottom: 8px;
        text-wrap: nowrap;
        display: none;

        @include responsive($m) {
          display: initial;
        }
      }
    }
    .whyReachifyMe-card-highlight-value {
      font-size: 34px;
      font-weight: 600;
      color: #111111;
      @include responsive($xl) {
        font-size: 28px;
      }
      @include responsive($m) {
        font-size: 22px;
      }
    }
    .whyReachifyMe-card-highlight-value-sub {
      font-size: 16px;
      font-weight: 500;
      color: #111111;
      @include responsive($xl) {
        font-size: 14px;
      }
    }
    .whyReachifyMe-card-description {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      min-height: 20px;
      @include responsive($xxl) {
        min-height: 28px;
      }
      @include responsive($xl) {
        font-size: 10px;
      }
      @include responsive($m) {
        font-size: 12px;
      }
    }
    .whyReachifyMe-card-summary-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      border-radius: 6px;
      margin-top: 10px;
      @include responsive($m) {
        align-items: center;
      }
      .whyReachifyMe-card-summary-text-container {
        font-size: 12px;
        font-weight: 600;
        text-wrap: nowrap;
        @include responsive($xl) {
          font-size: 10px;
        }
        @include responsive($m) {
          font-size: 12px;
        }
      }
    }
  }
}

.banner-container {
  background: #050505;
  height: 96px;
  margin-top: 30px;
  padding: 0px 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-image: url("../../../../public/image/pricing/banner.svg");
  border-radius: 8px;
  background-repeat: no-repeat;
  position: relative;
  @include responsive($xl) {
    padding: 0px 5%;
  }
  .banner-img {
    @include responsive($m) {
      display: none;
    }
  }
  .banner-text-container {
    margin-left: 2%;
    @include responsive($xl) {
      margin-left: 6px;
    }
    .banner-text {
      font-size: 20px;
      color: #ffffff;
      display: flex;
      gap: 6px;
      @include responsive($xl) {
        font-size: 18px;
      }
      @include responsive($m) {
        font-size: 14px;
      }
    }
  }
  .banner-button-container {
    margin-left: auto;
    .banner-button {
      padding: 13px 24px 13px 24px;
      border-radius: 50px;
      border: 1px solid linear-gradient(91.34deg, #fdae38 1.64%, #ef7d3d 100%);
      width: 200px;
      font-size: 12px;
      font-weight: 500;
      color: #121212;
      background: linear-gradient(91.09deg, #fae086 8.54%, #f3b453 92.32%);
      @include responsive($xl) {
        width: 120px;
        font-size: 10px;
        padding: 13px 16px 13px 16px;
      }
    }
  }
}

.testimonials-main-container {
  margin-top: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  @include responsive($m) {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 48px;
  }
  .testimonials-heading {
    font-size: 24px;
    font-weight: 500;
    color: #121212;
    @include responsive($m) {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .testimonials-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;
    @include responsive($m) {
      position: initial;
      margin-bottom: 74px;
      justify-content: center;
    }
    .testimonials-left-button {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: #ffffff;
      border: none;
    }
  }
  .testimonials-display-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    scroll-behavior: smooth;
    overflow-x: hidden;
    @include responsive($m) {
      flex-direction: row;
      gap: 16px;
      width: 95vw;
      max-width: 100%;
    }
    .testimonials-card-container {
      min-width: 48%;
      height: fit-content;
      min-height: 280px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 8px;

      @include responsive($m) {
        min-width: 100%;
        // max-width: 316px;
        height: fit-content;
        min-height: fit-content;
        padding: 22px;
      }
      .testimonials-user-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .testimonials-profile-image {
          height: 48px;
          width: 48px;
          border: 1px solid;
          border-radius: 50%;
          border-image-source: linear-gradient(
            180deg,
            #fdae38 0%,
            #ef7d3d 100%
          );
          @include responsive($m) {
            height: 40px;
            width: 40px;
          }
        }
      }
      .testimonials-description {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        @include responsive($xl) {
          font-size: 14px;
        }
      }
    }
  }
}
