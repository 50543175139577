@import '../../global.scss';

.button{
    padding:.5rem 1.5rem;
    border: 1px solid #E5E7EB;
    border-radius: $border-radius-for-button;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap:6px;
    cursor: pointer;
    .sx{
        font-size:12px;
    }
    @include responsive($m){
        font-size: 12px;
    }
}

.btn-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.disabled{
    background-color: #E5E7EB;
    color: #1F2937;
    cursor: not-allowed;
}

.secondary{
    font-size: 14px;
    font-weight: 500;
}

.primary{
    background-color: $secondary-color;
    // width:100%;
    color: #1F2937;
    cursor: pointer;
    font-weight: 500;
    font-size:14px;
    box-shadow: 0px 4px 25px 0px #FFC70040;
    &:hover{
        background-color: $button-hover-color;
    }

    @include responsive($m){
        font-size: 12px;
    }
}
.outlined{
    background-color: #fff;
    color: #262338;
    font-weight: 500;
    font-size:14px;
    border: 1px solid #1F2937;
    &:hover{
        background-color: #efefef;
    }

    @include responsive($m){
        font-size: 12px;
    }
}

.gradient{
    background: linear-gradient(91.09deg, #FAE086 8.54%, #F3B453 92.32%);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(91.34deg, #FDAE38 1.64%, #EF7D3D 100%);
    box-shadow: 0px 4px 25px 0px #0000001A;
    box-shadow: -5px -6px 15px -3px #FFC70045;
    box-sizing:border-box;
    // width: 110px;
    color: rgba(38, 35, 56, 1);
    font-size: 16px;
    font-weight: 500;
    @include responsive($m){
        font-size: 12px;
    }
    &:hover{
        background:linear-gradient(91.34deg, #FDAE38 1.64%, #EF7D3D 100%);
        box-shadow: 0px 10px 15px -3px rgba(255, 199, 0, 0.27);
    }
}
.darkBackground{
    background-color: rgba(18, 18, 18, 1);
    padding: .6rem 4rem;
    font-size: 16px;
    color: #fff;
    &:hover{
        background-color: rgba(20, 20, 20, 0.9);
    }
}

.loader{
    background-color: #F1F1F1;
    cursor:not-allowed;
    padding:0px;
    width:100%;
}

