@import "../../../global.scss";

//account section
.acc-info {
  .acc-head {
    font-size: 28px;
    font-weight: 400;

    @include responsive($m) {
      font-size: 18px;
    }
  }

  .acc-mail {
    margin-top: 5%;
    font-size: 16px;
    color: #374151;
    font-weight: 500;

    @include responsive($m) {
      font-size: 14px;
    }
  }

  .acc-mail_id {
    margin-top: 1%;
    font-size: 14px;
    color: #4d4d4d;

    @include responsive($m) {
      font-size: 12px;
    }
  }

  .acc-pass {
    margin-top: 7%;
    font-size: 16px;
    color: #374151;
    font-weight: 500;

    @include responsive($m) {
      font-size: 14px;
    }
  }

  .acc-pass_id {
    margin-top: 1%;
    font-size: 14px;
    color: #6366f1;
    font-weight: 600;
    cursor: pointer;

    @include responsive($m) {
      font-size: 12px;
    }
  }

  .acc-linked-user {
    display: flex;
    align-items: center;
    margin-top: 1.5%;
    font-size: 14px;
    margin-bottom: 1.5%;
    gap: 10px;
    img {
      border-radius: 50%;
    }

    @include responsive($m) {
      font-size: 11px;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

//manage-preference sections
.manage-pref {
  padding: 3%;

  .manage-pref-head {
    font-size: 28px;
    font-weight: 400;

    @include responsive($m) {
      font-size: 18px;
    }
  }

  .manage-pref-text {
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
    margin-top: 1%;

    @include responsive($m) {
      font-size: 12px;
    }
  }

  .hr-sec {
    border: 1px solid #e5e7eb;
    margin-top: 8%;
  }

  .manage-pref-btn {
    margin-top: 5%;
    font-size: 14px;
    color: #6366f1;
    font-weight: 600;
    cursor: pointer;

    @include responsive($m) {
      font-size: 12px;
    }
  }
}

//help and support section
.help-mail {
  margin-top: 1%;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 700;

  @include responsive($m) {
    font-size: 12px;
  }
}

//Change Password Modal section
.chng-pssword {
  font-size: 20px;
  font-weight: 500;
  color: #121212;

  @include responsive($m) {
    font-size: 16px;
  }
}

.tile-change {
  margin-top: 5%;
  font-size: 16px;
  color: #374151;

  @include responsive($m) {
    font-size: 12px;
  }
}

.txt-field-change {
  margin-top: 2%;
}

.change-pass-note {
  color: #4d4d4d;
  font-size: 12px;
  padding: 0 5%;
  font-weight: 300;
  margin-top: 2%;

  @include responsive($m) {
    font-size: 10px;
  }
}

.btn-change {
  display: flex;
  gap: 10px;
  margin-top: 5%;
}

.preference-container {
  width: 80%;
  margin: 0px auto;

  @include responsive($m) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    height: 88%;
  }
  .preference-container-input {
    margin-top: 12px;
    width: 100%;
    @include responsive($m) {
      margin-bottom: 100px;
    }
  }
}
.back-button-preference {
  flex: 1.25;
  @include responsive($m) {
    display: none;
  }
}

//raise ticket modal styles
.raise-ticket-modal {
  .main-header {
    font-size: 20px;
    font-weight: 500;
    color: #121212;

    @include responsive($m) {
      font-size: 16px;
    }
  }

  .text-from-raise-ticket {
    font-size: 14px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 20px;
    margin-top: 20px;

    @include responsive($m) {
      font-size: 12px;
    }
  }

  .raise-ticket-textarea {
    margin-top: 20px;
    height: 300px;
    width: 100%;
    padding: 20px;
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;
    border-radius: 20px;
    font-size: 16px;

    @include responsive($m) {
      font-size: 12px;
      border-radius: 16px;
      height: 246px;
    }
  }

  .hr-from-raise-ticket-modal {
    border: 1px solid #e5e7eb;
    margin-top: 20px;
  }

  .btns-from-raise-ticket-modal {
    display: flex;
    justify-content: start;
    gap: 20px;
    margin-top: 20px;
  }
}

.success-text {
  margin-top: 50px;
  color: #4d4d4d;
  font-size: 16px;
  text-align: center;
  margin-bottom: 50px;

  @include responsive($m) {
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.success-lottie {
  height: 200px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive($m) {
    height: 120px;
  }
}
.current-plan-card {
  padding: 16px 30px;
  border-radius: 16px;
  background: #fff;
  margin-top: 16px;
  @include responsive($m) {
    padding: 12px 16px;
  }
  .current-plan-card-header {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 400;
    color: #121212;
    padding-bottom: 16px;
    border-bottom: 1px solid #d4d4d4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include responsive($m) {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .mobile-see-more {
      display: none;
      @include responsive($m) {
        display: block;
        cursor: pointer;
      }
    }
    .view-invoices {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      color: #6366f1;
      cursor: pointer;
      @include responsive($m) {
        display: none;
      }
    }
  }
  .current-plan-card-content {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    align-items: center;
    @include responsive($m) {
      flex-direction: column;
      margin-top: 12px;
    }
    .current-plan-chip {
      min-width: 20%;
      @include responsive($m) {
        min-width: 100%;
      }
      .chip-name {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        color: #3f3b31;
        @include responsive($m) {
          font-size: 14px;
        }
      }
      .chip-value {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #625c4d;
        @include responsive($m) {
          font-size: 12px;
        }
      }
    }
  }
  .plan-cancelled-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #994040;
    border: 1px solid #994040;
    padding: 16px;
    border-radius: 12px;
    background-color: #ff60601a;
    margin-top: 16px;
    @include responsive($m) {
      font-size: 14px;
    }
  }
  .no-active-plan-box {
    margin-top: 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    .no-active-plan-logo {
      width: 24px;
    }
    .no-active-plan-details {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      color: #898373;
      @include responsive($m) {
        font-size: 14px;
      }
    }
  }
}

.chip-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  @include responsive($m) {
    display: none;
  }
}
.chip-more-content {
  font-family: Poppins;
  font-size: 14px;
  height: 34px;
  font-weight: 400;
  color: #3f3b31;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .chip-more-icon {
  }
  .chip-more-box {
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    width: 200px;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    padding: 10px;
    z-index: 100;
    box-shadow: 0px 2px 31px 0px #00000026;

    .chip-more-box-item {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      color: #121212;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background: #f4f4f4;
      }
    }
  }
}
