$loader-size: 50px;
$primary-color: orange;

@mixin loader-animation {
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.main-loader{
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.2);
}

.custom-loader {
  width: $loader-size;
  height: $loader-size;
  border-radius: 50%;
  
  
  background: 
    radial-gradient(farthest-side, $primary-color 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, $primary-color);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  @include loader-animation;
}
