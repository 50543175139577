$primary-color: #1F1F1F;
$secondary-color: #FFC700;
$default-border-radius: calc(10px + 1.5vw);
$secondary-border-radius: calc(10px + .5vw);
$nav-bar-item-height: 50px;
$body-padding: calc(12px + 1vw);
$button-hover-color: orange;
$border-radius-for-button:50px;
$xs: 480px;
$s: 768px;
$m: 900px;
$l: 1200px;
$xl: 1450px;
$xxl:1600px;

@mixin responsive($screen) {
    @media only screen and (max-width: $screen) {
        @content;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "poppins", sans-serif;
    color: $primary-color;
}

.logo{
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 16px;
    gap: 6px;
    font-size: 20px;
    font-weight: 600;
    @include responsive($m) {
        font-size: 14px;
        font-weight: 600;
        margin-left: 0px;
    }
}

.flex{
    display: flex;
    align-items: center;
}
.disabledCopy {
    user-select: none;
  }